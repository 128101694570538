import React from 'react';
import useSound from 'use-sound';
import './index.css';

import NotificationBubble from './NotificationBubble';
import sfx from '../../utils/sfx';

const Button = ({ icon, text, isSelected, onClick, notificationCount }) => {
  const [play] = useSound(sfx.AUDIO_HOVER);
  const [playClick] = useSound(sfx.AUDIO_ACTION_CLICK);

  let classes = 'btn';
  if (isSelected) classes = classes.concat(' btn-selected');
  return (
    <div className={classes} onClick={()=> {onClick(); playClick();}} onMouseEnter={() => play()}>
      <span className='btn-logo-box'>
        <img src={icon} alt='btn-logo' className='btn-logo' />
      </span>
      <span className='btn-text'>
        {text} <NotificationBubble count={notificationCount} />
      </span>
    </div>
  );
};

Button.propTypes = {};

export default Button;
