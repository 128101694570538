import * as ACTIONS from './action_types';
import api from '../../api';
import axios from '../../utils/axios/axios';

import { fetchGates, setProcessing } from '../../store/menuReducer/actions';
import { addLoader, hideLoader } from '../loadingReducer/actions';
import {fetchMessages} from "../messagesReducer/actions";
import {markGameIncomplete} from '../menuReducer/actions';
import {Mixpanel} from '../../utils/mixpanel';

export const loadGate = (title, id) => {  
  if(title === 'Professors'){
    Mixpanel.track("Game Start");
  }
  Mixpanel.track('Start Gate', {gate: title, title, id});
  return (dispatch) => {
    dispatch({
      type: ACTIONS.LOAD_NEW_GATE,
      payload: { title, id },
    });
    dispatch(visitTask(id));
  };
};

export const toggleResetState = () => {
  return (dispatch) => {
    dispatch({
      type: ACTIONS.TOGGLE_RESET_STATE,
      payload: {},
    });
  };
}

export const saveGateState = (gateId, gateState) => {
  return {
    type: ACTIONS.SAVE_GATE_STATE,
    payload: { gateId, gateState },
  };
};

export const tempLockGate = (gateId) => {
  return {
    type: ACTIONS.TEMP_LOCK_GATE,
    payload: { gateId },
  };
};

export const tempUnlockGate = (gateId) => {
  return {
    type: ACTIONS.TEMP_UNLOCK_GATE,
    payload: { gateId },
  };
};

export const submitGateAnswer = (gateId, answer) => {
  const url = `${api.task.SUBMIT_TASK_ANSWER}/${gateId}`;
  return (dispatch) => {
    axios
      .put(url, { answer })
      .then(({ status, data }) => {
        const { valid } = data;
        if (valid) {
          dispatch(fetchGates());
          dispatch(fetchMessages());
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
};

export const visitTask = (gateId) => {
  const url = `${api.task.VISIT_TASK}/${gateId}`;
  return (dispatch) => {
    axios
      .put(url)
      .then(({ status, data }) => {
        const { valid } = data;
        if (valid) {
          dispatch(fetchMessages());
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
};

export const resetGame = () => {
  const url = `${api.game.RESET_GAME_PROGRESS}`;
  return (dispatch) => {
    dispatch(addLoader());
    dispatch(setProcessing(true));
    axios
      .post(url)
      .then(({ status, data }) => {
        dispatch(fetchGates());
        dispatch(hideLoader());
        dispatch(fetchMessages());
        dispatch(markGameIncomplete());
        dispatch(toggleResetState());

        Mixpanel.track('Reset Game');
      })
      .catch((e) => {
        dispatch(hideLoader());
        console.log(e);
      });
  };
};


export const getAllSolutions = () => {
  const url = `${api.game.GET_ALL_ANSWERS}`;
  return (dispatch) => {
    dispatch(addLoader());
    axios
      .get(url)
      .then(({ status, data }) => {
        const { solutions } = data;
        let solutionArray = [];
        for(let k in solutions) {
          solutionArray[solutions[k].gameId] = solutions[k].answer;
        }
        console.log("Got all solutions from backend", solutionArray);
        dispatch({
          type: ACTIONS.SAVE_ALL_SOLUTIONS,
          payload: solutionArray,
        });
        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(hideLoader());
        console.log(e);
      });
  };
}