import React, { useState, useEffect } from 'react';
import './index.css';
import Popup from '../Popup/Popup';

import rogueLogo from '../../assets/images/rogueLogo.png';
import objCompleteLogo from '../../assets/images/objCompleteLogo.png';
import missionCompleteLogo from '../../assets/images/missionCompleteLogo.png';
import checkoutMoreGames from '../../assets/images/checkoutMoreGames.png';

import objCompleteLogo_mobile from '../../assets/images/objCompleteLogo_mobile.png';
import missionCompleteLogo_mobile from '../../assets/images/missionCompleteLogo_mobile.png';
import checkoutMoreGames_mobile from '../../assets/images/checkoutMoreGames_mobile.png';
import { Mixpanel } from '../../utils/mixpanel';

const CongratsScreen = ({ history }) => {
  const [congratsPopup, setCongratsPopup] = useState(true);

  const onClosePopup = (e) => {
    setCongratsPopup(false);
  };
  
  if (!congratsPopup) {
    return null;
  }

  return (
    <React.Fragment>
      <div className='congrats-popup'>
        <Popup
          title='CONGRATULATIONS!'
          headerBG='#1d2460'
          fontColor='#fff'
          onEventClose={(e) => onClosePopup(e)}
        >
          <div className='congrats-content'>
            <img className='rogue-logo' alt='Rogue Logo' src={rogueLogo} />

            <img
              className='obj-logo desktop'
              alt='Objective Complete'
              src={objCompleteLogo}
            />
            <img
              className='obj-logo mobile'
              alt='Objective Complete'
              src={objCompleteLogo_mobile}
            />

            <img
              className='mission-text desktop'
              alt='Mission Continues'
              src={missionCompleteLogo}
            />
            <img
              className='mission-text mobile'
              alt='Mission Continues'
              src={missionCompleteLogo_mobile}
            />

            <img
              className='more-games-text desktop'
              alt='Checkout more games'
              src={checkoutMoreGames}
            />
            <img
              className='more-games-text mobile'
              alt='Checkout more games'
              src={checkoutMoreGames_mobile}
            />

            <div className='more-games-btns'>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://store.theescapegame.com/collections/teg-unlocked/products/digital-version-teg-unlocked-the-heist-vol-1-chasing-hahn'
              >
                <button className='mr-4'>UNLOCKED: CHASING HAHN</button>
              </a>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://store.theescapegame.com/collections/teg-unlocked/products/teg-unlocked-the-heist-vol-2-silk-road-digital'
              >
                <button>UNLOCKED: THE SILK ROAD</button>
              </a>
            </div>
          </div>
        </Popup>
      </div>
    </React.Fragment>
  );
};

CongratsScreen.propTypes = {};

export default CongratsScreen;
