import * as ACTIONS from "./action_types";
import api from "../../api";
import axios from "../../utils/axios/axios";

const setProcessing = (payload) => ({ type: ACTIONS.SET_PROCESSING, payload });

const parseHint = ({
  id,
  content,
  gameId,
  title,
  puzzle
}) => ({
  id,
  content,
  gameId,
  title,
  puzzle
});

const parseHints = (hints) => {
  let hintsMap = [];
  hints.map((hint) => {
    let parsedHint = parseHint(hint);
    try {
      return hintsMap[hint.puzzle].push(parsedHint);
    } catch {
      hintsMap[hint.puzzle] = [];
      return hintsMap[hint.puzzle].push(parsedHint);
    }
  })
  return hintsMap;
};

export const fetchHints = () => {
  const url = api.customer.GET_CUSTOMER_HINTS;
  return (dispatch) => {
    dispatch(setProcessing(true));
    axios.get(url).then(({ data }) => {
      const { hints } = data;
      const newHints = parseHints(hints);

      let newGates = []
      for(let k in newHints){
        newGates.push({title: k});
      }

      dispatch({
        type: ACTIONS.FETCH_HINTS,
        payload: newHints,
      });
      dispatch({
        type: ACTIONS.FETCH_GATES,
        payload: newGates,
      });
    });
  };
};
