import * as ACTIONS from './action_types';
import api from '../../api';
import axios from '../../utils/axios/axios';

import { fetchVideos } from '../videosReducer/actions';
import { addLoader, hideLoader } from '../loadingReducer/actions';
import { getAllSolutions, loadGate } from '../gateReducer/actions';
import { fetchFiles } from '../filesReducer/actions';
import { fetchMessages } from '../messagesReducer/actions';

export const setProcessing = (payload) => ({ type: ACTIONS.SET_PROCESSING, payload });

const parseTask = ({
  answer,
  id,
  title,
  status,
  gameId,
  taskId,
  customerId,
}) => ({
  answer,
  id,
  title,
  status,
  gameId,
  taskId,
  customerId,
  isLocked: status === 'locked',
  isComplete: status === 'complete',
});

const parseTasks = (tasks) => tasks.map((task) => parseTask(task));

export const fetchGates = () => {
  const url = api.customer.GET_CUSTOMER_TASKS;
  return (dispatch) => {
    dispatch(addLoader());  
    dispatch(setProcessing(true));
    dispatch(fetchVideos());
    dispatch(fetchFiles());
    dispatch(fetchMessages());
    axios
      .get(url)
      .then(({ status, data }) => {
        const { tasks } = data;
        const gates = parseTasks(tasks);
        dispatch({
          type: ACTIONS.FETCH_GATES,
          payload: gates,
        });
        const lastUnlockedGate = gates.find(
          (gate) => gate.status === 'unlocked'
        );
        if(!lastUnlockedGate){
          dispatch({
            type: ACTIONS.GAME_COMPLETE
          });
        }else{
          dispatch(loadGate(lastUnlockedGate.title, lastUnlockedGate.gameId));
        }
        
        dispatch(hideLoader());
      })
      .catch((e) => {
        dispatch(hideLoader());
        console.log(e);
      });
  };
};


export const markGameIncomplete = () => {
  return (dispatch) => {
    dispatch({
      type: ACTIONS.GAME_INCOMPLETE
    });
  }
}