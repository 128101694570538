import * as ACTIONS from "./action_types";

const initialState = {
  fetching: false,
  user: {},
  token: '',
  authState: false
};

const authReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case ACTIONS.SET_PROCESSING:
      return {
        ...state,
        fetching: payload,
      };
    case ACTIONS.CURRENT_USER:
      return {
        ...state,
        user: payload
      };
    case ACTIONS.AUTH_STATUS:
        return {
          ...state,
          authState: payload
        };
    case ACTIONS.CURRENT_TOKEN:
        return {
          ...state,
          token: payload
        };
    default:
      return {
        ...state,
      };
  }
};

export default authReducer;
