import React from "react";
import { Provider } from "react-redux";

import Unlocked3Game from "./Containers/Unlocked3Game/Unlocked3Game";

import store from "./store";

function App() {
  return (
    <Provider store={store}>
      <Unlocked3Game />
    </Provider>
  );
}

export default App;
