import React from "react";
import { BrowserRouter as Router, Switch, Redirect, Route } from "react-router-dom";

import Unlocked3Route from "../Unlocked3Route/Unlocked3Route";

import Videos from "../../Components/Videos/Videos";
import Files from "../../Components/Files/Files";
import Messages from "../../Components/Messages/Messages";
import Hints from "../../Components/Hints/Hints";
import Settings from "../../Components/Settings/Settings";
import FileFullView from '../../Components/Files/FileFullView';
import Verify from '../../Components/Verify/Verify';

const Unlocked3Game = () => {
  return (
    <Router>
      <Switch>
        <Unlocked3Route exact path="/games" component={() => <div></div>} />
        <Unlocked3Route exact path="/files" component={Files} />
        <Unlocked3Route exact path="/messages" component={Messages} />
        <Unlocked3Route exact path="/videos" component={Videos} />
        <Unlocked3Route exact path="/hints" component={Hints} />
        <Unlocked3Route exact path="/settings" component={Settings} />
        <Route exact path="/fullview/:fileid" component={FileFullView} />
        <Route exact path="/" component={Verify} />
        <Redirect to="/games" />
      </Switch>
    </Router>
  );
};

Unlocked3Game.propTypes = {};

export default Unlocked3Game;
