import mixpanel from 'mixpanel-browser';
mixpanel.init('f8d9e64f44e1cb991642fa1facfef774');
const env_check = true;

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    const distinct_id = localStorage.getItem('user_id');
    if (env_check) mixpanel.track(name, {distinct_id, ...props});
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
