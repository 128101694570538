import React, { useEffect } from "react";
import PropTypes from "prop-types";
import loadingGif from '../../assets/images/air_loading.gif';

import "./index.css";
const Loader = ({ isLoading }) => {
  if(isLoading) {
    return (
        <div className="loading-container">
          <div className="loading-popup">
              <div className="popup-heading">
                  Loading
              </div>
              <div className="popup-container">
                  <img alt="air-loader" src={loadingGif} />
              </div>
          </div>
      </div>
    );
  }else{
    return "";
  }
};

Loader.propTypes = {
    isLoading: PropTypes.bool
};

export default Loader;