import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import {compose} from 'redux';
import {withRouter, useParams} from "react-router";
import { useSwipeable } from "react-swipeable";

import useSound from 'use-sound';
import './index.css';
import sfx from '../../utils/sfx';

import nextImgIcon from '../../assets/images/Unlocked 3 Messages_Next Message Icon.png';
import prevImgIcon from '../../assets/images/Unlocked 3 Messages_Previous Message Icon.png';

import {fetchFile} from '../../store/filesReducer/actions';

const FileFullView = ({ history }) => {
    const params = useParams();
    const [pageNum, setPageNum] = useState(0); 
    const [file, setFile] = useState({});
    const [playWoosh] = useSound(sfx.AUDIO_INVENTORY_CONTROL);
    const [playPopupClose] = useSound(sfx.AUDIO_POPUP_CLOSE);

    useEffect(() => {
        const fileId = params.fileid;
        getFile(fileId);
    }, []);

    useEffect(() => {
        playWoosh();
      }, [pageNum]);

    const getFile = async (fileId) => {
        const file = await fetchFile(fileId);
        setFile(file);
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => setPageNum(Math.min(pageNum+1, 6)),
        onSwipedRight: () => setPageNum(Math.max(pageNum-1, 0)),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    const onEventClose = () => {
        playPopupClose();
        history.goBack();
    }

    const fileContent = (fileData) => {
        if(!file.url) {
            return 'Loading...';
        }
        if(file.url !== '' && !file.url.includes(".zip") && !file.url.includes(".pdf") ){
            return <img alt="File Content" src={file.url} />;
        }
        let pages = {};
        try {
            pages = JSON.parse(fileData.pages);
        } catch (err) {
            pages = fileData.pages;
        }
    
        return (
            <span>
                <img alt="File Content" src={pages[pageNum]} /> 
                <div className="image-controls-container">
                    <div className="image-controls">
                        <div onClick={() => setPageNum(Math.max(pageNum-1, 0))} className={pageNum===0 ? "next-img faded-img" : "next-img"}>
                            <img alt="Previous" src={prevImgIcon} />
                        </div>
                        <div onClick={() => setPageNum(Math.min(pageNum+1, pages.length-1))} className={pageNum===pages.length-1 ? "prev-img faded-img" : "prev-img"}>
                            <img alt="Next" src={nextImgIcon} />
                        </div>
                    </div>
                </div>
            </span>
        );
      }
      return ( 
          <div className="full-view"  {...handlers}>
              <div className="file-close-btn" onClick={onEventClose}><i className="icon-close"></i></div>
              {fileContent(file)}
          </div>
      );
};

FileFullView.propTypes = {};

const mapStateToProps = ({ }) => ({
    
});

const mapDispatchToProps = {
    getFileById: (fileId) => fetchFile(fileId)
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
  )(FileFullView);