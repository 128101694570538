import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './index.css';
import Popup from '../Popup/Popup';

import MessagesList from './MessagesList';

import MessagesContent from './MessageContent';

import nextImgIcon from '../../assets/images/Unlocked 3 Messages_Next Message Icon.png';
import prevImgIcon from '../../assets/images/Unlocked 3 Messages_Previous Message Icon.png';

import {markMessageAsRead, fetchMessages} from '../../store/messagesReducer/actions';
import { Mixpanel } from '../../utils/mixpanel';

const Messages = ({ messages, history, readMessage, getAllMessages }) => {
  const [selectedMsg, setSelectedMsg] = useState(-1);

  useEffect(() => {
    getAllMessages();
    Mixpanel.track("Navigation", {"type": "global", "module": "messages"});
  }, []);

  const onClosePopup = e => {
    history.goBack();
  }

  function onChangeSelectedMsg(id, msg) {
    if (id === selectedMsg.id) {
      setSelectedMsg(-1);
      return;
    }
    if (id < 0 || id >= messages.length) {
      return;
    }
    if(!msg.isRead){
      readMessage(msg.id);
    }
    msg.id = id;
    setSelectedMsg(msg);
    Mixpanel.track("Message View", {...msg})
  }

  return (
    <div className="msgs-container">
      <Popup title="Messages" headerBG="#694190" fontColor="#fff" onEventClose={(e) => onClosePopup(e)}>
        <div className="msg-box">
          <div className="msg-left">
            <MessagesList
              onSelect={onChangeSelectedMsg}
              selectedIndex={selectedMsg.id}
              messages={messages}
            />
          </div>
          <div className="msg-right">
            <MessagesContent message={selectedMsg} />
            {selectedMsg !== -1 ? (
              <div className="msg-controls">
                <div
                  onClick={() =>
                    onChangeSelectedMsg(
                      selectedMsg.id - 1,
                      messages[selectedMsg.id - 1]
                    )
                  }
                  className="next-msg"
                >
                  <img style={selectedMsg.id===0? {opacity: 0.1} : {}} src={prevImgIcon} alt="previous button" />
                </div>
                <div
                  onClick={() =>
                    onChangeSelectedMsg(
                      selectedMsg.id + 1,
                      messages[selectedMsg.id + 1]
                    )
                  }
                  className="prev-msg"
                >
                  <img style={selectedMsg.id===messages.length-1? {opacity: 0.1} : {}} src={nextImgIcon} alt="next button" />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </Popup>
    </div>
  );
};

Messages.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      isRead: PropTypes.bool,
      id: PropTypes.number,
      title: PropTypes.string,
      subject: PropTypes.string,
      message: PropTypes.string,
      gameId: PropTypes.number,
      taskId: PropTypes.number,
      type: PropTypes.string,
      isPopUp: PropTypes.bool,
    })
  ),
};

const mapStateToProps = ({ messagesReducer }) => ({
  messages: messagesReducer.messages,
});

const mapDispatchToProps = {
  readMessage: (msgId) => markMessageAsRead(msgId),
  getAllMessages: () => fetchMessages()
};

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
