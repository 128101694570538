import React from 'react';
import useSound from 'use-sound';
import './index.css';
import sfx from '../../utils/sfx';
import { Mixpanel } from '../../utils/mixpanel';

const GateButton = ({
  isSelected,
  icon,
  text,
  isCompleted,
  isLocked,
  onClick,
}) => {
  const [play] = useSound(sfx.AUDIO_HOVER);
  const [playClick] = useSound(sfx.AUDIO_ACTION_CLICK);

  let classes = 'btn gate-btn';
  if (isSelected && !isLocked) classes = classes.concat(' gate-btn-selected');
  isCompleted
    ? (classes = classes.concat(' gate-btn-complete'))
    : isLocked
    ? (classes = classes.concat(' gate-btn-close'))
    : (classes = classes.concat(' gate-btn-open'));

  return (
    <div
      className={classes}
      onClick={() => {
        if (!isLocked) {
          Mixpanel.track("Navigation", {"type": "gate", "module": text});
          playClick();
          onClick();
        }
      }}
      onMouseEnter={() => play()}
    >
      <span className='btn-logo-box'>
        <img src={icon} alt='btn-logo' className='btn-logo' />
      </span>
      <span className='btn-text'>{text}</span>
    </div>
  );
};

GateButton.propTypes = {};

export default GateButton;
