import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";

import filesReducer from "./filesReducer/reducer";
import gateReducer from "./gateReducer/reducer";
import menuReducer from "./menuReducer/reducer";
import videosReducer from "./videosReducer/reducer";
import messagesReducer from "./messagesReducer/reducer";
import hintsReducer from "./hintsReducer/reducer";
import loadingReducer from "./loadingReducer/reducer";
import authReducer from "./authReducer/reducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  combineReducers({
    filesReducer,
    gateReducer,
    menuReducer,
    messagesReducer,
    videosReducer,
    hintsReducer,
    loadingReducer,
    authReducer
  }),
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
