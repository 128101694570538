import React, {useState} from 'react';
import './index.css';
import Popup from '../Popup/Popup';
import VideoModal from '../Videos/VideoModal';

const letterUrl = "https://unlocked3.s3.us-east-2.amazonaws.com/Letter+from+Humphreys/Letter+from+Humphreys-01.jpg";

const FinalLetter = ({ finalVideo }) => {

    const [videoPlayer, setVideoPlayer] = useState(false);
    const [ videoViewed, setVideoViewed] = useState(false);

    if(videoViewed) {
        return <React.Fragment></React.Fragment>;
    }
  return (
      <React.Fragment>
    <div className='final-letter-popup'>
        <Popup title='' headerBG="linear-gradient(45deg, #e64c23, #3a6bfe)" fontColor="#fff">
            <div className="final-letter-content">
                <div className="letter-container">
                    <img alt="Letter from Humphrey's" src={letterUrl} />
                </div>
                <div className="success-container">
                    <div className="success-message">YOU DID IT! <br/><br/>That letter will lead us straight to Humphreys. We're prepping a team to go to the location provided, just give us the word.<br/><br/>Fantastic work agent!<br/><br/>Driscoll out.</div>
                    <div><button onClick={() => setVideoPlayer(true)} >Get Humphreys!</button></div>
                </div>
            </div>
        </Popup>
    </div>
    {videoPlayer ? (
        <VideoModal
          videoId={finalVideo.id}
          title={finalVideo.title}
          url={finalVideo.url}
          onVideoPlayerClose = {()=>{setVideoPlayer(false);setVideoViewed(true)}}
        />
      ) : null}
    </React.Fragment>
  );
};

FinalLetter.propTypes = {};

export default FinalLetter;
