import React from 'react';
import useSound from 'use-sound';
import './index.css';
import sfx from '../../utils/sfx';

const FileIcon = ({ title, iconImg, isLocked, onClick, selectedFile, isRead }) => {
  const [play] = useSound(sfx.AUDIO_HOVER);

  let iconClass =
    selectedFile.url !== undefined ? 'file-icon blurred-icon' : 'file-icon';
  iconClass += isRead ? ' isread-opacity' : '';
  return (
    <div className={iconClass} onClick={onClick} onMouseEnter={() => play()}>
      <img src={iconImg} alt="file icon" />
      <div className="file-title">{title}</div>
    </div>
  );
};

FileIcon.propTypes = {};

export default FileIcon;
