import React, { useEffect } from 'react';
import './index.css';

import msgReadIcon from '../../assets/images/Unlocked 3 Messages_Read Icon.png';
import msgUnreadIcon from '../../assets/images/Unlocked 3 Messages_Unread Icon.png';
import msgCollapseBtn from '../../assets/images/Unlocked 3 Messages_Collapse Button.png';
import msgExpandBtn from '../../assets/images/Unlocked 3 Messages_Expand Button.png';

const MessagesList = ({ messages, selectedIndex, onSelect }) => {

  return messages.map((msg, index) => {
    const itemClass =
      index === selectedIndex ? 'msg-list-item selected-item' : 'msg-list-item';
    const msgIcon = msg.isRead ? msgReadIcon : msgUnreadIcon;
    return (
      <div key={index}>
        <div onClick={() => onSelect(index, msg)} className={itemClass}>
          <div className="msg-from">FROM: AIR</div>
          <div className="msg-subject">
            <div className="subject-text">{msg.subject}</div>
            <div className="msg-options">
              <div className="msg-status">
                <img src={msgIcon} alt="Read Message" />
              </div>

              <div className="msg-toggle-btn">
                {index === selectedIndex ? (
                  <img src={msgCollapseBtn} alt="open message" />
                ) : (
                  <img src={msgExpandBtn} alt="delete message" />
                )}
              </div>
            </div>
          </div>
        </div>

        {index === selectedIndex ? (
          <div className="collapsed-msg-content">{msg.message}</div>
        ) : (
          ''
        )}
      </div>
    );
  });
};

MessagesList.propTypes = {};

export default MessagesList;
