import React, { useEffect } from "react";

import "./index.css";

const VideoIcon = ({ title, iconImg, isLocked, onClick, selectedVideo, thumbnail }) => {
  const iconClass = selectedVideo.url !== undefined ? "file-icon blurred-icon" : "file-icon"
  return (
    <div className={iconClass} onClick={onClick}>
        <img alt="Video Thumbnail" className="video-thumbnail" src={thumbnail} />
        <img alt="Play Icon" className="video-icon" src={iconImg} />
        <div className="file-title">{title}</div>
    </div>
  );
};

VideoIcon.propTypes = {
};

export default VideoIcon;
