import React from "react";
import "./index.css";

const Header = () => {
  return (
    <div className="header">
      <div className="logo-box">
        <img
          src="/images/unlocked3-logo.png"
          alt="unlocked3-logo"
          className="logo"
        />
      </div>
      <h1 className="heading">The Agency For International Reconnaissance</h1>
    </div>
  );
};

Header.propTypes = {};

export default Header;
