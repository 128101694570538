import customer from './customer';
import task from './task';
import videos from './videos';
import game from './game';
import messages from './message';
import files from './files';

const api = {
  customer,
  task,
  videos,
  game,
  messages,
  files
};

export default api;
