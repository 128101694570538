import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './index.css';
import useSound from 'use-sound';
import sfx from '../../utils/sfx';
import Popup from '../Popup/Popup';
import { resetGame } from '../../store/gateReducer/actions';
import {setNewToken, updateUser, AUTH_URL} from '../../store/authReducer/actions';
import {Mixpanel} from '../../utils/mixpanel';

const Settings = ({ history, doResetGame, currentUser, doAbandonGame, doUpdateProfile }) => {
  const [play] = useSound(sfx.AUDIO_ACTION_CLICK);
  const [formData, setFormData] = useState({});

  useEffect(()=> {
    setFormData({
        first_name: currentUser.first_name,
        last_name: currentUser.last_name,
        email: currentUser.email,
        postal: currentUser.postal || ''
    });
    Mixpanel.track("Navigation", {"type": "global", "module": "settings"});
  }, []);

  const onFormChange = e => {
    setFormData({
        ...formData,
        [e.target.name]: e.target.value,
    });
  }

  const onEventUpdateProfile = e => {
      e.preventDefault();
      doUpdateProfile(formData);
  }

  const onClosePopup = e => {
    history.goBack();
  }

  const onEventResetGame = e => {
      e.preventDefault();
      doResetGame();
      play();
  }

  const onEventAbandonMission = e => {
      e.preventDefault();
      Mixpanel.track("Logout");
      doAbandonGame();
      window.location.replace(AUTH_URL);
  }

  return (
    <div className="files-container settings-container">
      <Popup title="Settings" headerBG="#000" fontColor="#fff" onEventClose={(e) => onClosePopup(e)}>
          <div className="settings-section">
            <div className="settings-heading">
                <h3>Profile</h3>
            </div>
            <div className="settings-content">
                <div className="profile-img-container">
                    <img alt="User Profile" className="profile-img" style={{padding:'0.5rem'}} src={'/images/unlocked3-logo.png'} />
                </div>
                <div className="profile-form">
                    <form onChange={onFormChange}>
                        <div className="input-row">
                            <div className="half-col mr-2">
                                <span className="input-label">First Name</span>
                                <input name="first_name" defaultValue={formData.first_name} className="input-text" />
                            </div>
                            <div className="half-col">
                                <span className="input-label">Last Name</span>
                                <input name="last_name" defaultValue={formData.last_name}  className="input-text" />
                            </div>
                        </div>
                        <div className="input-row">
                            <div className="full-col">
                                <span className="input-label">Email</span>
                                <input name="email" defaultValue={formData.email} disabled={true} className="input-text" />
                            </div>
                        </div>
                        <div className="input-row">
                            <div className="half-col">
                                <span className="input-label">Zip/Postal Code</span>
                                <input name="postal" defaultValue={formData.postal} className="input-text" />
                            </div>
                        </div>
                    </form>
                    <div className="input-row mt-2 settings-buttons">
                        <button onClick={onEventUpdateProfile} className="btn btn-primary settings-btn">Save Changes</button>
                        <button onClick={onEventResetGame} className="btn btn-primary settings-btn">Reset Game</button>
                        <button onClick={onEventAbandonMission} className="btn btn-primary settings-btn abandon-btn">Abandon Mission</button>
                    </div>
                </div>
            </div>

          </div>
      </Popup>
    </div>
  );
};

Settings.propTypes = {
};

const mapStateToProps = ({ authReducer }) => ({
    currentUser: authReducer.user
});

const mapDispatchToProps = {
    doResetGame: () => resetGame(),
    doAbandonGame: () => setNewToken(''),
    doUpdateProfile: (prof) => updateUser(prof)
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);