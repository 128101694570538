const GAME_SERVICE = "/games";

const START_GAME = `${GAME_SERVICE}/start`;
const RESET_GAME_PROGRESS = `${GAME_SERVICE}/reset`;
const GET_ALL_ANSWERS = `/solutions`;

const game = {
    START_GAME,
    RESET_GAME_PROGRESS,
    GET_ALL_ANSWERS
};

export default game;
