import axios from "axios";


export const SERVER_URL  = "https://d2tvuy9li6nieb.cloudfront.net/api/v1";
//export const SERVER_URL  = "http://localhost:8000/api/v1";

console.log("SERVER_URL:", SERVER_URL);

const getJWT = () => {
  return `Bearer ${localStorage.getItem("token")}`;
};

const getOptions = () => {
  const options = {
    headers: {},
  };

  const JWT = getJWT();
  if (JWT) {
    options.headers = {
      Authorization: JWT,
    };
  }

  return options;
};

const prepareURL = (api) => `${SERVER_URL}${api}`;

const WRAPPER = {
  get: (api) => axios.get(prepareURL(api), getOptions()),
  post: (api, requestData) =>
    axios.post(prepareURL(api), requestData, getOptions()),
  put: (api, requestData) =>
    axios.put(prepareURL(api), requestData, getOptions()),
  delete: (api) => axios.delete(prepareURL(api), getOptions()),
};

export default WRAPPER;
