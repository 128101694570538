import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import useSound from 'use-sound';
import './index.css';
import sfx from '../../utils/sfx';
import VideoIcon from './VideoIcon';
import Popup from '../Popup/Popup';

import placeholderIcon from '../../assets/images/video-icon-placeholder.png';

import VideoPopup from './VideoPopup';

import {fetchVideos} from '../../store/videosReducer/actions';
import { Mixpanel } from '../../utils/mixpanel';

const Videos = ({ videos, history, fetchAllVideos }) => {
  const [playPopupOpen] = useSound(sfx.AUDIO_POPUP_OPEN);
  const [playPopupClose] = useSound(sfx.AUDIO_POPUP_CLOSE);
  useEffect(() => {
    Mixpanel.track("Navigation", {"type": "global", "module": "videos"});
    fetchAllVideos();
  }, []);

  const [selectedVideo, setSelectedVideo] = useState({});

  const onClosePopup = e => {
    history.goBack();
  }

  function onChangeSelectedVideo(vid) {
    
    if (selectedVideo.url !== undefined) {
      playPopupClose();
      return setSelectedVideo({});
    }
    Mixpanel.track("Video Start", {...vid});
    setSelectedVideo(vid);
    playPopupOpen();
  }

  return (
    <div className="files-container">
      <Popup title="Videos" headerBG="#fced29" onEventClose={(e) => onClosePopup(e)}>
        <div className="videos-grid">
          {videos.map((video) => {
            return (
              <VideoIcon
                onClick={() => {
                  onChangeSelectedVideo(video);
                }}
                key={video.id}
                title={video.title}
                iconImg={placeholderIcon}
                thumbnail={video.thumbnail}
                selectedVideo={selectedVideo}
              />
            );
          })}
        </div>
      </Popup>
      {selectedVideo.url !== undefined ? (
        <VideoPopup
          url={selectedVideo.url}
          title={selectedVideo.title}
          onClose={() => onChangeSelectedVideo({})}
        />
      ) : (
        ''
      )}
    </div>
  );
};

Videos.propTypes = {
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      isWatched: PropTypes.bool,
      id: PropTypes.number,
      gameId: PropTypes.number,
      taskId: PropTypes.number,
      title: PropTypes.string,
      thumbnail: PropTypes.string,
      url: PropTypes.string,
    })
  ),
};

const mapStateToProps = ({ videosReducer }) => ({
  videos: videosReducer.videos,
});

const mapDispatchToProps = {
  fetchAllVideos: () => fetchVideos()
};

export default connect(mapStateToProps, mapDispatchToProps)(Videos);
