import * as ACTIONS from './action_types';
import api from '../../api';
import axios from '../../utils/axios/axios';
import { fetchGates } from '../menuReducer/actions';
import { addLoader, hideLoader } from '../loadingReducer/actions';
import {Mixpanel} from '../../utils/mixpanel';

const setProcessing = (payload) => ({ type: ACTIONS.SET_PROCESSING, payload });

export const AUTH_URL = 'https://tegunlocked.com/';

export const setNewToken = (token) => {
  return (dispatch) => {
    localStorage.removeItem('token');
    localStorage.setItem('token', token);
    dispatch({
      type: ACTIONS.CURRENT_TOKEN,
      payload: token,
    });
  };
};

export const fetchUser = () => {
  const url = api.customer.GET_CUSTOMER_INFO;
  return (dispatch) => {
    console.log('FETCHING USER!');
    
    dispatch(setProcessing(true));
    axios
      .get(url)
      .then(({ status, data }) => {
        const { user } = data;
        dispatch({
          type: ACTIONS.CURRENT_USER,
          payload: user,
        });
        dispatch({
          type: ACTIONS.AUTH_STATUS,
          payload: true,
        });
        Mixpanel.identify(user.id);
        localStorage.setItem('user_id', user.user_id);
        Mixpanel.people.set({
          $first_name: user.first_name,
          $last_name: user.last_name,
          $email: user.email,
          $user_id: user.user_id,
          $distinct_id: user.user_id
        });
        dispatch(setProcessing(false));
        dispatch(fetchGates());
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: ACTIONS.AUTH_STATUS,
          payload: false,
        });
        dispatch(setProcessing(false));
      });
  };
};

export const updateUser = (prof) => {
  const url = api.customer.UPDATE_CUSTOMER_INFO;
  console.log('UPDATING USER!');
  return (dispatch) => {
    dispatch(addLoader());
    axios
      .put(url, prof)
      .then(() => {
        Mixpanel.track('Profile Update', {...prof});
        dispatch(hideLoader());
      })
      .catch((error) => {
        console.log(error);
        dispatch(hideLoader());
      });
  };
};

export const isAuthenticated = (getState) => {
  return getState().authReducer.user.id !== undefined;
};
