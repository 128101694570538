import * as ACTIONS from "./action_types";

export const addLoader = () => {
  return (dispatch) => {
    dispatch({
      type: ACTIONS.ADD_LOADER
    });
   };
};

export const hideLoader = () => {
  return (dispatch) => {
    dispatch({
      type: ACTIONS.HIDE_LOADER
    });
  };   
};

