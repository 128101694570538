import React from "react";
import {useLocation} from 'react-router-dom';
import { Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import Unlocked3Dashboard from "../Unlocked3Dashboard/Unlocked3Dashboard";
import {withRouter} from "react-router";
import {compose} from 'redux';
import {setNewToken} from '../../store/authReducer/actions';
import FrameLayout from '../../Components/GateFrame/FrameLayout';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Unlocked3Route = ({ authenticatedUser, component: Component, ...rest }) => {
  const query = useQuery();

  if(authenticatedUser.id !== undefined){
    return (
      <Route
        {...rest}
        render={(props) => (
          <Unlocked3Dashboard>
            <FrameLayout shouldDisplay = {props.location.pathname === '/games'} {...props} />
            <Component {...props} />
          </Unlocked3Dashboard>
        )}
      />
    );
  }else{
    const token = query.get("token") && query.get("token") !== '' ? `?token=${query.get("token")}` : '';
    return <Redirect to={`/${token}`} />
  }

};

Unlocked3Route.propTypes = {};

const mapStateToProps = ({ authReducer }) => ({
  authenticatedUser: authReducer.user
});
  
const mapDispatchToProps = {
  setToken: (token) => setNewToken(token),
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Unlocked3Route);