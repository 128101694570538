import React from 'react';

import './index.css';

import gatesListIcon from '../../assets/images/folder_icon.png';
import rightArrowIcon from '../../assets/images/Unlocked 3 Hints D_Gate Arrow Open.png';

const HintGatesList = ({ gates, selectedGate, setSelectedGate, hintsHtml }) => {
  return (
    <div className="gates-list">
                    <div className="gates-list-header">
                <img alt="Gate List" className="gates-list-icon" src={gatesListIcon} />
            </div>
        <div className="gates-list-container">
            <div className="gates-list-body">
                <ul className="gates-list-ul">
                    {gates.map((gate, index) => {
                        let classes = selectedGate.title === gate.title ? "gate-list-item item-selected" : "gate-list-item";
                        (gate.title === "FAQ") ? classes += ` bg-color-faq` : classes += ` bg-color-${(index)%9}`;
                        return <React.Fragment  key={index}> <li className={classes} onClick={() => {selectedGate.title === gate.title ? setSelectedGate({}) : setSelectedGate({index:index, ...gate})}}>
                            <div>{gate.title}</div>
                            <div><img alt="Right Arrow" src={rightArrowIcon} /></div>
                            
                        </li>
                        {gate.title === selectedGate.title ? <div className="d-d-none">{hintsHtml}</div> : ''}
                        </React.Fragment>
                        ;
                    })}
                </ul>
            </div>
        </div>
    </div>
  );
};

HintGatesList.propTypes = {};

export default HintGatesList;