import React from 'react';
import './index.css';

import gatesListIcon from '../../assets/images/folder_icon.png';
import rightArrowIcon from '../../assets/images/hint_icon_right.png';

const HintsList = ({ hints, selectedGate, selectedHint, setSelectedHint, hintContentHtml }) => {

  return (
    <div className="" style={selectedGate.title === undefined ? {display: 'none'} : {}}>
        
        <div className="hints-list-header">
           <img alt="Gates List" className="hints-list-icon" src={gatesListIcon} />
        </div>
        <div className="hints-list-container">

            <div className="hints-list-body">
                <ul className="hints-list-ul">
                    {hints.length ? hints.map((hint, index) => {
                        const classes = selectedHint.title === hint.title ? "item-selected" : "";
                        return <React.Fragment key={index}> <li onClick={() => {selectedHint.title === hint.title ? setSelectedHint({}) : setSelectedHint({index:index, ...hint})}} className={classes}>
                            <div>{hint.title}</div>
                            <div><img alt="Right Arrow" src={rightArrowIcon} /></div>
                        </li>
                        { selectedHint.title === hint.title  ? <div className="d-d-none">{hintContentHtml} </div> : ''}
                        </React.Fragment>;
                    }) : ''}
                </ul>
            </div>
        </div>
    </div>
  );
};

HintsList.propTypes = {};

export default HintsList;