import React, { useEffect } from "react";
import useSound from 'use-sound';
import './index.css';
import sfx from '../../utils/sfx';

const Popup = ({children, title, headerBG, fontColor, onEventClose }) => {
  
  const [playPopupClose] = useSound(sfx.AUDIO_POPUP_CLOSE);

  const onClose = () => {
      playPopupClose();
      onEventClose();
  }

  return (
        <div className="popup-3d">
            <div className="popup-container">
                <div className="popup-header" style={{background: headerBG}}>
                    <div className="popup-title" style={{color: fontColor}}>{title}</div>
                    <div className="popup-close-btn" style={onEventClose ? {} : {display: 'none'}} onClick={onClose}><i className="icon-close"></i></div>
                </div>
                <div className="popup-body">
                    {children}
                </div>
            </div>
        </div>
  );
};

Popup.propTypes = {
};

export default Popup;