import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import { useHistory } from "react-router"
import { connect } from 'react-redux';
import {setNewToken, fetchUser} from '../../store/authReducer/actions';

import './index.css';
import { Mixpanel } from '../../utils/mixpanel';

function useQuery() {
 return new URLSearchParams(useLocation().search);
}

const Verify = ({ setToken, fetchCurrentUser, isProcessing, isAuthenticated, currentUser }) => {
    const query = useQuery();
    let history = useHistory();

    useEffect(() => {
        if(query.get("token") && query.get("token") !== ''){
            Mixpanel.track("Login", {token: query.get("token")});
            setToken(query.get("token"));
        }
        fetchCurrentUser();
    }, []);

    useEffect(() => {
        if(currentUser.id !== undefined) {
            history.push("/games");
        }
    }, [currentUser])

  return (
    <div className="verify-screen">
        {
            (isProcessing) ? <p>Logging in...</p> : 
            // (!isProcessing && !isAuthenticated) ? <p>Verification failed! <br /> Please login again at <a href="https://tegunlocked.com/">tegunlocked.com</a></p> :
            (!isProcessing && !isAuthenticated) ? <p>Rogue Scientist 1990 is experiencing a temporary outage!<br /> We're sorry for the inconvenience and will have everything back up and running soon!</p> :
            <p>Verification successful!</p>
        }
        
    </div>
  );
};

Verify.propTypes = {};

const mapStateToProps = ({ authReducer }) => ({
    isProcessing: authReducer.fetching,
    isAuthenticated: authReducer.authState,
    currentUser: authReducer.user
});
  
const mapDispatchToProps = {
    setToken: (token) => setNewToken(token),
    fetchCurrentUser: () => fetchUser()
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Verify);