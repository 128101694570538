import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import GateButton from "../Button/GateButton";

import gateLocked from "../../assets/images/gate-locked.png";
import gateUnlocked from "../../assets/images/gate-unlocked.png";

import { loadGate } from "../../store/gateReducer/actions";

const GatesMenu = ({ gates, pathname, redirect, loadNewGate, selectedGateId}) => {
  const isGateSelect = (gateId) => pathname === "/games" && selectedGateId === gateId;

  return (
    <>
      <button disabled className="btn gate-btn dummy-btn">
        Menu
      </button>
      {gates.map((task) => (
        <GateButton
          key={task.gameId}
          icon={task.isLocked ? gateLocked : gateUnlocked}
          isSelected={isGateSelect(task.gameId)}
          text={task.title}
          onClick={() => {
             if (pathname !== "/games") redirect("/games");
             loadNewGate(task.title, task.gameId);
          }}
          isCompleted={task.isComplete}
          isLocked={task.isLocked}
        />
      ))}
    </>
  );
};

GatesMenu.defaultProps = {
  gates: [],
};

GatesMenu.propTypes = {
  gates: PropTypes.arrayOf(
    PropTypes.shape({
      answer: PropTypes.oneOf([null, PropTypes.object]),
      id: PropTypes.number,
      title: PropTypes.string,
      status: PropTypes.string,
      gameId: PropTypes.number,
      taskId: PropTypes.number,
      customerId: PropTypes.number,
      isLocked: PropTypes.bool,
      isComplete: PropTypes.bool,
    })
  ).isRequired,
};

const mapStateToProps = ({ menuReducer, gateReducer }) => ({
  gates: menuReducer.gates,
  //loadNewGate: (gateTitle, gateId) => loadGate(gateTitle, gateId),
  selectedGateId: gateReducer.gateId
});

const mapDispatchToProps = {
  loadNewGate: loadGate
};

export default connect(mapStateToProps, mapDispatchToProps)(GatesMenu);
