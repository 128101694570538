import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Header from '../../Components/Header/Header';
import Unlocked3Menu from '../Unlocked3Menu/Unlocked3Menu';
import VideoModal from '../../Components/Videos/VideoModal';

import { fetchFiles } from '../../store/filesReducer/actions';
import { fetchMessages } from '../../store/messagesReducer/actions';
import { fetchVideos } from '../../store/videosReducer/actions';

import './index.css';
import Loader from '../../Components/Loader/Loader';
import FinalLetter from '../../Components/Files/FinalLetter';
import CongratsScreen from '../../Components/Files/CongratsScreen';

const Unlocked3Dashboard = ({
  getVideos,
  getMessages,
  getFiles,
  children,
  isLoading,
  introVideo,
  isGameComplete
}) => {
  useEffect(() => {
    getVideos();
    getMessages();
    getFiles();
  }, []);

  return (
    <div className="unlocked-3-dashboard">
      <Header />
      <div className="content-box">
        <div className="unlocked-3-dashboard-content-area">
          {children}
          <Loader isLoading={isLoading} />
        </div>
        <div className="unlocked-3-dashboard-menu-area">
          <Unlocked3Menu />
        </div>
      </div>
      {introVideo && introVideo.title!=='5 - Finale' ? (
        <VideoModal
          videoId={introVideo.id}
          title={introVideo.title}
          url={introVideo.url}
        />
      ) : null}

      {isGameComplete && introVideo ? (
        <FinalLetter finalVideo={introVideo} />
      ) : null}

      {isGameComplete && !introVideo ? (
        <CongratsScreen />
      ) : null}
    </div>
  );
};

Unlocked3Dashboard.propTypes = {
  getFiles: PropTypes.func,
  getVideos: PropTypes.func,
  getMessages: PropTypes.func,

  introVideo: PropTypes.oneOf([null, PropTypes.shape({})]),
};

const mapStateToProps = ({ videosReducer, loadingReducer, menuReducer }) => ({
  introVideo: videosReducer.unwatchedVideo,
  isLoading: loadingReducer.isLoading,
  isGameComplete: menuReducer.isGameComplete
});

const mapDispatchToProsp = {
  getVideos: () => fetchVideos(),
  getMessages: () => fetchMessages(),
  getFiles: () => fetchFiles(),
};

export default connect(mapStateToProps, mapDispatchToProsp)(Unlocked3Dashboard);
