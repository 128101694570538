import React, { useEffect } from "react";
import "./index.css";

const MessagesContent = ({message}) => {
  
  if(message === -1){
      return ""
  }
  return (
    <div className="msg-content">
        <div className="msg-from">FROM: AIR</div>
        <div className="msg-subject">{message.subject}</div>
        <div className="msg-text">{message.message}</div>
    </div>
  );
};

MessagesContent.propTypes = {
};

export default MessagesContent;
