import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './index.css';
import {
  submitGateAnswer,
  saveGateState,
  toggleResetState,
  getAllSolutions,
} from '../../store/gateReducer/actions';

import { Mixpanel } from '../../utils/mixpanel';

const messages = {
  LOAD_GATE: 'LOAD_GATE',
  NEW_GATE_STATE: 'NEW_GATE_STATE',
  RESET_PROGRESS: 'RESET_PROGRESS',
};

const constructMessages = {
  WINDOW_LOADED: 'WINDOW_LOADED',
  LOAD_GATE_STATE: 'LOAD_GATE_STATE',
  ON_SAVE_GATE_STATE: 'ON_SAVE_GATE_STATE',
  ON_GATE_SUBMIT: 'ON_GATE_SUBMIT',
  ON_RESET_GAME: 'ON_RESET_GAME',
  ON_CLOSE_POPUP: 'ON_CLOSE_POPUP',
};

const GateFrame = ({
  id,
  title,
  url,
  history,
  gateStates,
  gateSolutions,
  submitAnswer,
  saveNewGateState,
  gatesMenu,
  resetState,
  changeResetState,
  fetchAllSolutions,
  isGameComplete,
}) => {
  let myIframe = useRef(null);
  const [isListenerLoaded, changeListenerLoadState] = useState(false);

  const constructMessagesListener = (event) => {
    const { type, payload } = event.data;
    if (myIframe.current) {
      switch (type) {
        case constructMessages.WINDOW_LOADED:
          if (!gateSolutions[id]) {
            return;
          }
          let prevGateState = gateStates[id] ? gateStates[id] : {};
          let gateSolution = gateSolutions[id] ? gateSolutions[id] : {};

          if (resetState) {
            myIframe.current.contentWindow.postMessage(
              {
                type: messages.RESET_PROGRESS,
                payload: {},
              },
              url
            );
            changeResetState();
          }
          return myIframe.current.contentWindow.postMessage(
            {
              type: messages.LOAD_GATE,
              payload: {
                gateId: id,
                gateState: JSON.stringify(prevGateState),
                gateSolution: JSON.stringify(gateSolution),
              },
            },
            url
          );
        case constructMessages.LOAD_GATE_STATE:
          return (
            myIframe.current.contentWindow.postMessage({
              type: messages.NEW_GATE_STATE,
              payload: {
                gateState: JSON.stringify(gateStates[id]),
              },
            }),
            url
          );
        case constructMessages.ON_RESET_GAME:
          return (
            myIframe.current.contentWindow.postMessage({
              type: messages.RESET_PROGRESS,
              payload: {},
            }),
            url
          );
        case constructMessages.ON_GATE_SUBMIT:
          const userAnswer = JSON.parse(payload.answer);
          submitAnswer(payload.gateID, userAnswer);
          const gateInfo = gatesMenu[payload.gateID-1];
          Mixpanel.track('Gate Complete', { gate:  gateInfo.title, ...gateInfo });
          if(gateInfo.title === 'Location') Mixpanel.track('Game Complete', {});
          return;
        case constructMessages.ON_SAVE_GATE_STATE:
          const newGateState = JSON.parse(payload.gateState);
          saveNewGateState(id, newGateState);
          return;
        case constructMessages.ON_CLOSE_POPUP:
          history.goBack();
          return;
        default:
          return;
      }
    }
  };

  const addListeners = () => {
    // Listener
    window.addEventListener('message', constructMessagesListener);
    return () => {
      window.removeEventListener('message', constructMessagesListener);
    };
  };

  useEffect(() => {
    if (id !== null && myIframe.current && !isListenerLoaded) {
      addListeners();
      changeListenerLoadState(true);
    }
  }, [id]);

  useEffect(() => {
    function sendResetCallback() {
      if (myIframe.current && resetState) {
        myIframe.current.contentWindow.postMessage(
          {
            type: messages.RESET_PROGRESS,
            payload: {},
          },
          url
        );
        changeResetState();
      }
    }

    sendResetCallback();
  }, [resetState]);

  const loadGateInIframe = () => {
    if (myIframe.current && id !== null) {
      let prevGateState = gateStates[id] ? gateStates[id] : {};
      let gateSolution = gateSolutions[id] ? gateSolutions[id] : {};
      myIframe.current.contentWindow.postMessage(
        {
          type: messages.LOAD_GATE,
          payload: {
            gateId: id,
            gateState: JSON.stringify(prevGateState),
            gateSolution: JSON.stringify(gateSolution),
          },
        },
        url
      );
    }
  };

  useEffect(() => {
    loadGateInIframe();
  }, [id]);

  useEffect(() => {
    if (myIframe.current && gateSolutions.length && id !== null) {
      addListeners();
    }
  }, [gateSolutions]);

  useEffect(() => {
    fetchAllSolutions();
    if (myIframe.current) {
      addListeners();
    }
  }, []);

  return (
    <div className='gameplayer-container'>
      {gateSolutions.length && gateSolutions[id] && gatesMenu ? (
        <iframe
          ref={myIframe}
          title={`Unlocked3-${title}`}
          src={url}
          className='responsive-iframe'
        />
      ) : isGameComplete ? (
        <div className='congrats-sections'></div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

GateFrame.defaultProps = {
  url: 'https://unlocked3-construct.tegunlocked.com/',
};

GateFrame.propTypes = {
  title: PropTypes.string,
  id: PropTypes.number,
  url: PropTypes.string,
  gateStates: PropTypes.object,
};

const mapStateToProps = ({ gateReducer, menuReducer }) => ({
  id: gateReducer.gateId,
  title: gateReducer.gateTitle,
  gateStates: gateReducer.gateStates,
  gateSolutions: gateReducer.gateSolutions,
  gatesMenu: menuReducer.gates,
  resetState: gateReducer.resetState,
  isGameComplete: menuReducer.isGameComplete,
});

const mapDispatchToProps = {
  submitAnswer: (gateId, userAnswer) => submitGateAnswer(gateId, userAnswer),
  saveNewGateState: (gateId, gatePayload) => saveGateState(gateId, gatePayload),
  changeResetState: () => toggleResetState(),
  fetchAllSolutions: () => getAllSolutions(),
};

export default connect(mapStateToProps, mapDispatchToProps)(GateFrame);
