import React from "react";

import "./index.css";

const NotificationBubble = ({ count }) => {
    if(!count || parseInt(count) === 0 || parseInt(count) <= 0) {
        return '';
    }
    return (
        <span className="notification-bubble">
            {count}
        </span>
    );
};

NotificationBubble.propTypes = {};

export default NotificationBubble;