import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Button from "../../Components/Button/Button";
import GatesMenu from "../../Components/GatesMenu/GatesMenu";

import filesLogo from "../../assets/images/file-logo.png";
import filesLogoSelected from "../../assets/images/file-logo-selected.png";
import helpLogo from "../../assets/images/help-logo.png";
import helpLogoSelected from "../../assets/images/help-logo-selected.png";
import messagesLogo from "../../assets/images/messages-logo.png";
import messagesLogoSelected from "../../assets/images/messages-logo-selected.png";
import settingsLogo from "../../assets/images/settings-logo.png";
import settingsLogoSelected from "../../assets/images/settings-logo-selected.png";
import videosLogo from "../../assets/images/videos-logo.png";
import videosLogoSelected from "../../assets/images/videos-logo-selected.png";

import { fetchGates } from "../../store/menuReducer/actions";
import "./index.css";

const BTN_TEXT_FILES = "Files";
const BTN_TEXT_MESSAGES = "Messages";
const BTN_TEXT_VIDEOS = "Videos";
const BTN_TEXT_HINTS = "Hints";
const BTN_TEXT_SETTINGS = "Settings";

const Unlocked3Menu = ({
  getGates,
  history,
  location: { pathname },
  totalUnreadMsgs,
  totalUnreadFiles
}) => {
  useEffect(() => {
    getGates();
  }, []);

  const redirect = (path) => {
    history.push(path);
  };

  const isSelected = (selectedTitle = '') => {
    return pathname.includes(selectedTitle.toLowerCase());
  };

  return (
    <div className="menu-container">
      <section className="hint-section">
        <Button icon={isSelected(BTN_TEXT_HINTS) ? helpLogoSelected : helpLogo}
          text={BTN_TEXT_HINTS}
          isSelected={isSelected(BTN_TEXT_HINTS)}
          onClick={() => {
            redirect("/hints", BTN_TEXT_HINTS);
          }}
        />
        <Button icon={isSelected(BTN_TEXT_SETTINGS) ? settingsLogoSelected : settingsLogo}
          text={BTN_TEXT_SETTINGS}
          isSelected={isSelected(BTN_TEXT_SETTINGS)}
          onClick={() => {
            redirect("/settings", BTN_TEXT_SETTINGS);
          }}
        />
      </section>
      <section className="options-section">
        <Button
          icon={isSelected(BTN_TEXT_FILES) ? filesLogoSelected : filesLogo}
          text={BTN_TEXT_FILES}
          isSelected={isSelected(BTN_TEXT_FILES)}
          onClick={() => {
            redirect("/files", BTN_TEXT_FILES);
          }}
          notificationCount={totalUnreadFiles}
        />
        <Button
          icon={
            isSelected(BTN_TEXT_MESSAGES) ? messagesLogoSelected : messagesLogo
          }
          text={BTN_TEXT_MESSAGES}
          isSelected={isSelected(BTN_TEXT_MESSAGES)}
          onClick={() => {
            redirect("/messages", BTN_TEXT_MESSAGES);
          }}
          notificationCount={totalUnreadMsgs}
        />
        <Button
          icon={isSelected(BTN_TEXT_VIDEOS) ? videosLogoSelected : videosLogo}
          text={BTN_TEXT_VIDEOS}
          isSelected={isSelected(BTN_TEXT_VIDEOS)}
          onClick={() => {
            redirect("/videos", BTN_TEXT_VIDEOS);
          }}
        />
      </section>
      <section className="gates-section">
        <GatesMenu pathname={pathname} redirect={redirect} />
      </section>
    </div>
  );
};

Unlocked3Menu.propTypes = {
  loadNewGate: PropTypes.func,
  selectedGateTitle: PropTypes.string,
};

const mapStateToProps = ({ gateReducer, messagesReducer, filesReducer }) => ({
  selectedGateTitle: gateReducer.gateTitle,
  totalUnreadMsgs: messagesReducer.totalUnread,
  totalUnreadFiles: filesReducer.totalUnread
});

const mapDispatchToProps = {
  getGates: () => fetchGates(),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Unlocked3Menu)
);
