const AUDIO_HOVER = 'https://unlocked3.s3.us-east-2.amazonaws.com/sfx/hover.mp3';
const AUDIO_POPUP_OPEN = 'https://unlocked3.s3.us-east-2.amazonaws.com/sfx/Click+11.wav';
const AUDIO_POPUP_CLOSE = 'https://unlocked3.s3.us-east-2.amazonaws.com/sfx/Click+12.wav';
const AUDIO_NEW_MESSAGE = 'https://unlocked3.s3.us-east-2.amazonaws.com/sfx/BELLS_Simple%26Nice_01.wav';
const AUDIO_NEW_FILE = 'https://unlocked3.s3.us-east-2.amazonaws.com/sfx/Bonus+Point.wav';
const AUDIO_INVENTORY_CONTROL = 'https://unlocked3.s3.us-east-2.amazonaws.com/sfx/Wooshes8.wav';
const AUDIO_ACTION_CLICK = 'https://unlocked3.s3.us-east-2.amazonaws.com/sfx/Click+14.wav';

export default {
    AUDIO_HOVER,
    AUDIO_POPUP_OPEN,
    AUDIO_POPUP_CLOSE,
    AUDIO_NEW_MESSAGE,
    AUDIO_NEW_FILE,
    AUDIO_INVENTORY_CONTROL,
    AUDIO_ACTION_CLICK
}