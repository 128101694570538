import React, {useEffect} from 'react';
import './index.css';

import gatesListIcon from '../../assets/images/folder_icon.png';
import { Mixpanel } from '../../utils/mixpanel';

let isRecorded = false;
const HintContent = ({ selectedGate, selectedHint }) => {
  
  useEffect(() => {
    if(selectedHint.id && !isRecorded) {
      Mixpanel.track("Hint View", {...selectedHint});
      isRecorded = true;
    }
    if(!selectedHint.id){
      isRecorded = false;
    }
  }, [selectedHint])

  const getContent = () => {
    let formattedContent = selectedHint.content;
    const EMAIL = 'support@tegunlocked.com';
    if(selectedHint?.content?.includes(EMAIL)){
      return <React.Fragment>Check the hint guide to make sure your dashboard is not working properly. Then email <a href={`mailto:${EMAIL}`}>{EMAIL}</a> with any issues you may have.</React.Fragment>;
    }
    return <React.Fragment>{formattedContent}</React.Fragment>;
  }
  return (
    <div className="" style={selectedHint.title === undefined ? {display: 'none'} : {}}>
        <div className="hints-content-header">
           <img alt="Hints Content Icon" className="hints-content-icon" src={gatesListIcon} />
        </div>
        <div className="hints-content-container">
            <div className="hints-content-body">
                {getContent()}
            </div>
        </div>
    </div>
  );
};

HintContent.propTypes = {};

export default HintContent;