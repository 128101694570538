import * as ACTIONS from "./action_types";

const initialState = {
  gateTitle: "",
  gateId: null,

  gateSolutions: [],

  gateStates: {},
  resetState: false
};

const gateReducer = (state = { ...initialState }, { type, payload }) => {
  switch (type) {
    case ACTIONS.SAVE_ALL_SOLUTIONS:
      return {
        ...state,
        gateSolutions: payload
      };
    case ACTIONS.LOAD_NEW_GATE:
      return {
        ...state,
        gateTitle: payload.title,
        gateId: payload.id,
      };
    case ACTIONS.SAVE_GATE_STATE:
      return {
        ...state,
        gateStates: {
          ...state.gateStates,
          [payload.gateId]: {
            ...payload.gateState,
          },
        },
      };
    case ACTIONS.TEMP_LOCK_GATE:
      return {
        ...state,
        solvedGates: [...state.solvedGates, payload.gateId],
      };
    case ACTIONS.TEMP_UNLOCK_GATE:
      return {
        ...state,
        tempLockedGates: state.tempLockedGates.filter(
          (id) => id !== payload.gateId
        ),
      };
    case ACTIONS.TOGGLE_RESET_STATE: 
      return {
        ...state,
        resetState: !state.resetState
      };
    default:
      return {
        ...state,
      };
  }
};

export default gateReducer;
