import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './index.css';
import Popup from '../Popup/Popup';

import HintGatesList from './HintGatesList';
import HintsList from './HintsList';
import HintContent from './HintContent';

import { fetchHints } from '../../store/hintsReducer/actions';
import { Mixpanel } from '../../utils/mixpanel';

const Hints = ({ hints, history, getHints, gates }) => {
  
  const [totalHints] = useState({});
  useEffect(() => {
    function fetchHints() {
      getHints();
    }
    fetchHints();
    Mixpanel.track("Navigation", {"type": "global", "module": "hints"});
  }, [totalHints]);

  const [selectedGate, setSelectedGate] = useState({});
  const [selectedHint, setSelectedHint] = useState({});

  const onClosePopup = e => {
    history.goBack();
  }

  const changeGate = (gate) => {
      
      if(gate.title === undefined) {
        setSelectedGate({});  
        setSelectedHint({});
      }else{
        setSelectedGate(gate);  
        setSelectedHint({});
      }
  }

  const getHintContentHtml = <HintContent selectedGate={selectedGate} selectedHint={selectedHint}  />;
  const getHintsListHtml =   <HintsList hints={selectedGate.title ? hints[selectedGate.title] : {}} selectedGate={selectedGate} selectedHint={selectedHint} setSelectedHint={setSelectedHint} hintContentHtml= {getHintContentHtml} />

  return (
    <div className="files-container hints-container">
      <Popup title="Hints" headerBG="#e65778" fontColor="#fff" onEventClose={(e) => onClosePopup(e)}>
        <div className="hints-section">
            <HintGatesList selectedGate={selectedGate} setSelectedGate={changeGate} gates={gates} hints={hints} selectedHint={selectedHint} setSelectedHint={setSelectedHint} hintsHtml={getHintsListHtml} />
            <div className="d-m-none hints-list">{getHintsListHtml}</div>
            <div className="d-m-none hints-content">{getHintContentHtml}</div>
        </div>
      </Popup>
    </div>
  );
};

Hints.propTypes = {
};

const mapStateToProps = ({ hintsReducer }) => ({
  hints: hintsReducer.hints,
  gates: hintsReducer.gates
});

const mapDispatchToProps = {
    getHints: () => fetchHints(),
};

export default connect(mapStateToProps, mapDispatchToProps)(Hints);